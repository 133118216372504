<template>
    <div v-if="user.uid" class="home-page">
        <hackathons-sidebar />

        <div class="content">
            <active-hackathon-banner v-if="activeHackathon" style="margin-bottom: 1rem" />

            <div class="latest-cards">
                <LatestProjects />
                <LatestComments />
                <LatestIdeas />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import HackathonsSidebar from '@/components/HackathonsSidebar';
import ActiveHackathonBanner from '@/components/ActiveHackathonBanner';
import LatestComments from '@/components/LatestComments';
import LatestProjects from '@/components/LatestProjects';
import LatestIdeas from '@/components/LatestIdeas';
import '@/assets/images/page-not-found.svg';

export default {
    components: {
        HackathonsSidebar,
        ActiveHackathonBanner,
        LatestComments,
        LatestProjects,
        LatestIdeas,
    },

    data() {
        return {
            loading: true,
            collapseHeader: false,
            uploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
        };
    },

    mounted() {
        if (!this.user?.uid) this.$router.replace({ name: 'login' });
    },

    computed: {
        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
            comments: ({ hackathons }) => hackathons.comments,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
        }),
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
    .home-page {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: $spacing-200;
        overflow: hidden;
        overflow: auto;
    }

    .content {
        overflow: auto;
        height: calc(100vh - 104px);
        border-radius: $border-radius;
        scrollbar-width: none;
        scrollbar-color: $color-gray $color-gray-900;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: $color-gray-900;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-gray-700;
            border-radius: 5px;
            border: 4px solid $color-gray-900;
        }
    }

    .latest-cards {
        display: grid;
        gap: $spacing-200;
        grid-template-columns: repeat(3, calc(calc(100vw - 214px) / 3));
        width: 90vw;

        @media($small) {
            grid-template-columns: 100%;
            width: auto;
        }
    }
</style>
