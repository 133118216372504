<template>
    <DsCard class="latest-ideas">
        <header class="card-header">
            <h5>
                Latest ideas
            </h5>

            <div class="button-row">
                <DsFilledButton gray @click="$router.push({ name: 'ideas.new' })">
                    Submit idea
                </DsFilledButton>

                <DsFilledButton
                    gray
                    @click="$router.push({ name: 'ideas' })"
                >
                    All ideas
                </DsFilledButton>
            </div>
        </header>

        <div class="projects">
            <projects-list
                v-if="sortedIdeas.length"
                :projects="sortedIdeas"
                compact
                @selected="(project) => $router.push({ name: 'idea', params: { id: project.id }})"
            />
        </div>
    </DsCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    computed: {
        ...mapState({
            projects: ({ projects }) => projects.projects,
        }),

        ...mapGetters({
            getProject: 'projects/getProject',
            getUser: 'users/getUser',
        }),

        sortedIdeas() {
            return this.projects
                ?.filter(({ hackathonId }) => !hackathonId)
                ?.sort((a, b) => {
                    if (a.createdDate > b.createdDate) return -1;
                    if (a.createdDate < b.createdDate) return 1;

                    return 0;
                })?.slice(0, 5);
        },
    },
};
</script>

<style lang="scss" scoped>
.latest-ideas {
    background: $color-orange-050;
}
</style>
