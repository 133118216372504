<template>
    <div class="login-page">
        <div class="particles">
            <vue-particles
                click-mode="push"
                click-effect
                color="#ffffff"
                hover-effect
                hover-mode="grab"
                line-linked
                lines-color="#ffffff"
                shape-type="circle"
                :line-opacity="0.4"
                :lines-distance="150"
                :lines-width="2"
                :move-speed="3"
                :particle-opacity="0.7"
                :particle-size="2"
                :particles-number="80"
            />
        </div>


        <div class="login-box">
            <ds-logo />

            <h1>Hackathons</h1>

            <!-- Share, Collaborate, Learn, Hack, Develop, Browse Etc... -->

            <DsFilledButton
                v-if="user.uid"
                class="login-button"
                @click="logout"
            >
                Log out
            </DsFilledButton>

            <DsFilledButton
                v-else
                :loading="loading"
                class="login-button"
                @click="login"
            >
                Login
            </DsFilledButton>

            <br />

            <small>Use your @keap Google account</small>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
    data() {
        return {
            loading: false,
        };
    },

    mounted () {
        if (this.user.uid) this.$router.push({ name: 'home' });
    },

    watch: {
        // eslint-disable-next-line
        ['user.uid'](value) {
            if (value) this.checkAccess();
        },
    },

    computed: {
        ...mapState({
            googleToken: ({ auth }) => auth.googleToken,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            guestsList: 'users/guestsList',
        }),
    },

    methods: {
        async checkAccess() {
            this.loading = true;

            const userEmail = this.user?.email;

            if (userEmail?.endsWith('@keap.com')) {
                this.$router.push({ name: 'home' });

                return;
            }

            try {
                await this.$store.dispatch('users/LOAD_GUEST_LIST');

                if (this.guestsList.includes(userEmail)) {
                    this.$router.push({ name: 'home' });
                } else {
                    this.$error({ message: 'Access restricted.', bottom: true });
                    this.loading = false;
                    this.logout();
                }
            } catch (e) {
                //
            }
        },

        logout() {
            firebase.auth().signOut().then(() => {
                this.$store.commit('auth/SET_USER', { email: '' });
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.$router.push({ name: 'login' });
            }).catch(() => {});
        },

        login() {
            this.loading = true;

            if (!this.googleToken) {
                this.firebaseSignin();

                return;
            }

            const { currentUser } = firebase.auth();

            if (!currentUser) {
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.firebaseSignin();
            }
        },

        firebaseSignin() {
            const provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                    firebase.auth().signInWithRedirect(provider);
                })
                .catch((error) => {
                    throw error;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .login-page {
        display: flex;
        justify-content: center;
    }

    .login-box {
        display: flex;
        flex-direction: column;
        width: 320px;
        max-width: 100%;
        align-items: center;
        margin-top: 100px;
        z-index: 1;
        position: absolute;
        padding: $spacing-400;
        border-radius: $border-radius;
        transition: background-color 2000ms ease;
        backdrop-filter: blur(8px);
        background-color: rgba(0, 0, 0, .1);

        h1 {
            font-size: $font-size-lg;
            color: $color-yellow-050;
        }

        .login-button {
            margin-top: $spacing-400;
            --button-background-color: black;
            --button-text-color: white;
        }

        small {
            color: $color-gray-500;
        }
    }
</style>

<style lang="scss">
.particles {
    position: fixed;
    left: 0;
    top: 0;

    canvas {
        width: 100vw !important;
        height: 100vh !important;
    }
}
</style>
