<template>
    <DsCard>
        <header class="card-header">
            <h5>
                Latest projects
            </h5>

            <DsFilledButton
                gray
                @click="$router.push({ name: 'projects' })"
            >
                All projects
            </DsFilledButton>
        </header>

        <projects-list
            v-if="sortedProjects.length"
            compact
            :projects="sortedProjects"
            @selected="(project) => $router.push({ name: 'project', params: { id: project.id }})"
        />

        <br />
    </DsCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    computed: {
        ...mapState({
            projects: ({ projects }) => projects.projects,
        }),

        ...mapGetters({
            getProject: 'projects/getProject',
            getUser: 'users/getUser',
        }),

        sortedProjects() {
            return this.projects
                ?.filter(({ hackathonId }) => hackathonId)
                ?.sort((a, b) => {
                    if (a.createdDate > b.createdDate) return -1;
                    if (a.createdDate < b.createdDate) return 1;

                    return 0;
                })?.slice(0, 3);
        },
    },
};
</script>
